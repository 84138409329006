import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { deparam } from '../../../../modules/global';

// Mixins
import isResizable from '../../../mixins/resizable';

// Base
import Col from '../../base/col';
import Row from '../../base/row';

// Related Components
import Content from './sign_in_up/sign_up/content';
import SignInUp from './sign_in_up';
import TrustedCompanies from './index/trusted_companies';

class _Index extends React.Component {
  static propTypes = {
    isDesktop: PropTypes.bool.isRequired,
    activeTab: PropTypes.number,
    forgotPassword: PropTypes.bool,
    opportunityId: PropTypes.number,
    consentFormId: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onChangeUserType: PropTypes.func,
  }

  static defaultProps = {
    onChangeUserType: () => { },
    activeTab: 1,
  }

  constructor(props) {
    super(props);

    const params = deparam(location.search.slice(1));

    this.state = {
      userType: params.ut,
      isSignIn: props.activeTab === 1,
      isCreate: props.activeTab === 2,
    }
  }

  handleChangeUserType = (userType) => {
    const { onChangeUserType } = this.props;
    this.setState({
      userType
    }, () => onChangeUserType());
  }

  setActiveTab = (activeTab) => {
    if (activeTab === 1) {
      this.setState({ isSignIn: true, isCreate: false });
    }
    if (activeTab === 2) {
      this.setState({ isSignIn: false, isCreate: true });
    }
  }

  render() {
    const {
      forgotPassword, activeTab, opportunityId, consentFormId, email, firstName, lastName, tokenId,
      isDesktop, isTablet, isMobile,
    } = this.props;
    const { isSignIn, isCreate, userType } = this.state;

    return(
      <div className='fixed-width-layout'>
        <div className='margin-center'>
          <Row className={classNames('margin-left-20 margin-right-20', { 'margin-top-30': isDesktop })}>
            <Col s={12} l={8} offsetLarge={2} className='padding-top-30 relative'>
              { isSignIn &&
                <React.Fragment>
                  <h1 className='center-align margin-bottom-10 xl2-font bold'>
                    Hi!<span className='primary-color'> Welcome </span>back.
                  </h1>
                  <div className='xxsm-font center-align'>Sign in to your Oomple account.</div>
                </React.Fragment>
              }
              {
                isCreate &&
                <React.Fragment>
                  <h1 className='center-align margin-bottom-10 xl2-font bold'>
                    Hi!<span className='primary-color'> Welcome </span>to Oomple.
                  </h1>
                  {
                    (userType === 'consultant' || userType === undefined) &&
                    <div className='xxsm-font center-align'>
                      Empowering on-demand professionals to find top contracts,<br/>
                      network and manage their work all in one centralized hub.
                    </div>
                  }
                  {
                    userType === 'agent' &&
                    <div className='xxsm-font center-align'>
                      Leveraging an elastic workforce has never been easier.<br />
                      Find, Connect with and Hire On-Demand talent at your fingertips.<br />
                      Try for free and access an active network of over 65,000 On-Demand Professionals.
                    </div>
                  }
                </React.Fragment>
              }
              <img
                src='https://www.oomple.com/hubfs/assets/oomple/assets/signup/signup_womanwaving.png'
                alt='Woman waves her left hand and smiles.'
                className={classNames('absolute top-0', {
                  'right--30 ': isDesktop,
                  'right--50 ': isDesktop && isCreate,
                  'right-0': isTablet,
                  'hide': isMobile,
                })}
                width='110'
              />
            </Col>
          </Row>
          <Row
            className={classNames('signin margin-left-20 margin-right-20', {
              'flex height-600 margin-top-20 padding-top-40 padding-bottom-20': isDesktop,
              'padding-bottom-40': !isDesktop && isSignIn,
            })}
            nested
          >
            <Col s={12} l={6} offsetLarge={3} className='margin-bottom-20'>
              <SignInUp {...{
                forgotPassword,
                activeTab,
                opportunityId,
                consentFormId,
                tokenId,
                email,
                firstName,
                lastName,
                userType,
                allowUserType: true,
                onChangeUserType: this.handleChangeUserType,
                setActiveTab: this.setActiveTab,
              }} />
            </Col>
          </Row>
          {
            isCreate &&
            <Content />
          }
          <div className='flex justify-center'>
            <TrustedCompanies  />
          </div>
        </div>
      </div>
    )
  }
}

export default isResizable(_Index);