import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

// Mixins
import isResizable from '../../../../../../mixins/resizable';

// Base
import Card from '../../../../../base/card';
import Col from '../../../../../base/col';
import Row from '../../../../../base/row';

class _Consultant extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
  }

  get freeBenefits() {
    return [
      'Profile & Networking',
      'Marketplace Access',
      'Opportunity Search',
      'Hassle-free Payments',
      'Management Tools',
    ]
  }

  get benefits() {
    return [
      'Health Plans',
      'Insurance Coverage',
      'Discount Mar',
      'Online Prescriptions',
    ]
  }

  get whyUs() {
    return [
      {
        title: 'Profile & Network',
        description: 'Your customized profile enables you to showcase your skills, experience, market yourself and network with top companies and professionals.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_network.png',
      },
      {
        title: 'Specialized Contracts',
        description: 'Companies on Oomple are looking for experienced and specialized experts to work on important projects. Contracts are high-paying and on average 14-months long.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_target.png',
      },
      {
        title: 'Better Matching',
        description: 'Our Ai matching, that uses over 50 data points, combined with human review, ensures the most accurate and fastest role matching.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_matching.png',
      },
      {
        title: 'Transparency',
        description: 'You have transparency of the market, companies and the process from start to hire. You’re in control of your own rate. No rate compromises or hidden mark-ups.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_transparency_eye.png',
      },
      {
        title: 'Management Tools',
        description: 'Manage your freelance career in one place and with more ease with integrated management tools and hassle-free, secure payments.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_manage.png',
      },
      {
        title: 'Exclusive Benefits ',
        description: 'Access the to first of its kind and à la carte individual benefits for freelance professionals with corporate-level quality and coverage.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_benefitsss.png',
      },
    ]
  }

  render() {
    const { isMobile } = this.props;

    return (
      <Row>
        <Col s={12} className='margin-bottom-30'>
          <div className='max-width-450 margin-center'>
            <div className='gray-900 xsm-font center-align'>
              Sign up for your free membership &amp; you can also unlock access
              to exclusive benefit plans for freelance professionals.
            </div>
          </div>
        </Col>
        <Col s={12} className='bg-gray-300 margin-bottom-50'>
          <div className='center-align primary-color bold lg-font margin-top-50 margin-bottom-30'>
            Here’s what you can look forward to
          </div>
          <div className='max-width-700 margin-center'>
            <Row className='margin-bottom-50'>
              <Col s={12} m={6} className='margin-bottom-20'>
                <Card className='padding-20 max-width-300 margin-center min-height-250'>
                  <div className='bold med-font center-align margin-bottom-20'>
                    Oomple Free Platform
                  </div>
                  <ul className='list-style-none padding-left-0 margin-bottom-0 max-width-230 margin-center'>
                    {
                      this.freeBenefits.map((benefit, i) => (
                        <li key={i} className='margin-bottom-15'>
                          <div className='margin-left-15 inline-block'>
                            <FontAwesomeIcon
                              className='primary-color'
                              icon={faCheck} size='sm'
                            />
                          </div>
                          <div className='margin-left-10 inline-block xsm-font'>{benefit}</div>
                        </li>
                      ))
                    }
                  </ul>
                </Card>
              </Col>
              <Col s={12} m={6} className='margin-bottom-20'>
                <Card className='padding-20 max-width-300 margin-center min-height-250'>
                  <div className='bold med-font center-align margin-bottom-10'>
                    Oomple Benefits
                  </div>
                  <div className='xxsm-font margin-bottom-15'>
                    Access to affordable, corporate-quality,
                    benefit plans for freelance professionals.
                  </div>
                  <ul className='list-style-none padding-left-0 margin-bottom-20 max-width-230 margin-center'>
                    {
                      this.benefits.map((benefit, i) => (
                        <li key={i} className='margin-bottom-10'>
                          <div className='margin-left-15 inline-block'>
                            <FontAwesomeIcon
                              className='primary-color'
                              icon={faCheck} size='sm'
                            />
                          </div>
                          <div className='margin-left-10 inline-block xsm-font'>{benefit}</div>
                        </li>
                      ))
                    }
                  </ul>
                  <div className='xxsm-font primary-color center-align'>
                    Optional add-on plan
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col s={12} className='margin-bottom-50'>
          <div className='bold lg-font center-align margin-bottom-30'>
            Why choose us
          </div>
          <div className='max-width-1000 margin-center'>
            <Row className='flex flex-wrap'>
              {
                this.whyUs.map((data, i) => (
                  <Col s={12} m={6} l={4} key={i} className='margin-bottom-30'>
                    <div className='margin-bottom-15 height-80 flex vertical-align justify-center'>
                      <img
                        src={data.icon}
                        className='width-80'
                      />
                    </div>
                    <div className='sm-font primary-color bold center-align margin-bottom-15'>
                      {data.title}
                    </div>
                    <div className={classNames('xxsm-font', {
                      'margin-left-15 margin-right-15': !isMobile
                    })}>
                      {data.description}
                    </div>
                  </Col>
                ))
              }
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}
export default isResizable(_Consultant);