import React from 'react';

// Modules
import { deparam } from '../../../../../../modules/global';

// Models
import User from '../../../../../../models/v2/user';

// Related Components
import Company from './content/company';
import Consultant from './content/consultant';

export default class Content extends React.Component {
  render() {
    const params = deparam(location.search.slice(1));
    const userType = !!params.ut ? params.ut : '';

    return (
      <React.Fragment>
        {
          userType === User.CONSULTANT &&
          <Consultant />
        }
        {
          userType === User.AGENT &&
          <Company />
        }
      </React.Fragment>
    )
  }
}