import React from 'react';
import { createRoot } from 'react-dom/client';
import Index from '../../../../components/v4/public/users/index';


const element = document.getElementById('sign-in-up');

document.addEventListener('DOMContentLoaded', () => {
  if (element) {
    const {
      activeTab, forgotPassword, consentFormId, email, firstName, lastName, tokenId,
    } = element.dataset;
    const root = createRoot(element);
    root.render(
      <Index {...{
        activeTab: (activeTab ? parseInt(activeTab) : undefined),
        forgotPassword: forgotPassword === 'true',
        consentFormId: (consentFormId ? parseInt(consentFormId) : undefined),
        tokenId: (tokenId ? tokenId : ''),
        email,
        firstName,
        lastName,
      }} />
    );
  }
});