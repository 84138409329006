import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Mixins
import isResizable from '../../../../../../mixins/resizable';

// Base
import Col from '../../../../../base/col';
import Row from '../../../../../base/row';

class _Company extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
  }

  get whyUs() {
    return [
      {
        title: 'Marketplace Model',
        description: 'Direct access to an active network of over 60,000 professionals and their self-managed profiles. Get visibility on their rates, availability, experience & skills.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_transparency_eye.png',
      },
      {
        title: 'Highly Skilled Talent',
        description: 'On-demand professionals on Oomple are experienced and specialized experts that provide high-quality work for your projects and the expertise you need to get results.',
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_target.png',
      },
      {
        title: 'Data-Driven Matching',
        description: "Leverage over 50 data points to match you to the right professionals. Using  Oomple, you'll get recommendations for potential candidates that meet your needs.",
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_matching.png',
      },
      {
        title: 'Risk Management',
        description: "We're confident you’ll find professionals and grow your network. There are no upfront costs, no additional fees to hire & you can cancel anytime, hassle-free.",
        icon: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/sign_up/sign_up_icon_shield.png',
      },
    ]
  }

  render() {
    const { isMobile } = this.props;

    return (
      <Row>
        <Col s={12} className='margin-bottom-50'>
          <div className={classNames('bold center-align margin-bottom-20', {
            'xl2-font margin-top-100': !isMobile,
            'lg-font margin-top-20': isMobile,
          })}>
            Why choose us
          </div>
          <div className='max-width-700 margin-center'>
            <div className={classNames('center-align', {
              'xsm-font': isMobile,
            })}>
              Gain direct access to an active network of over 60,000 on-demand professionals and tools to Find, Match with and Manage candidates all in one centralized Hub.
            </div>
          </div>
        </Col>
        <Col s={12} className='margin-bottom-50'>
          <div className='max-width-1000 margin-center'>
            <Row className='flex flex-wrap'>
              {
                this.whyUs.map((data, i) => (
                  <Col s={12} m={6} key={i} className='margin-bottom-30'>
                    <div className='margin-bottom-15 height-80 flex vertical-align justify-center'>
                      <img
                        src={data.icon}
                        className='width-80'
                      />
                    </div>
                    <div className='sm-font primary-color bold center-align margin-bottom-15'>
                      {data.title}
                    </div>
                    <div className={classNames('xxsm-font', {
                      'margin-left-15 margin-right-15': !isMobile
                    })}>
                      {data.description}
                    </div>
                  </Col>
                ))
              }
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}
export default isResizable(_Company);